import { Directive, ElementRef, inject, Renderer2 } from '@angular/core';

import { environment } from '@@environments/environment';

@Directive({
	selector: '[data-testid]',
	standalone: true
})
export class DataTestidDirective {
	readonly #el = inject(ElementRef);
	readonly #renderer = inject(Renderer2);

	constructor() {
		if (environment.production) {
			this.#renderer.removeAttribute(this.#el.nativeElement, 'data-testid');
		}
	}
}
